import React from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import '../styles/footer.css';  // Import the CSS file for styling

const Footer = () => {
    const domain = "https://tablebound.com"; // Replace with your domain

    return (
        <footer style={styles.footer}>
            <p style={styles.footerText}>© {new Date().getFullYear()} Tablebound LLC. All rights reserved.</p>
            <div style={styles.footerLinks}>
                <a href={`${domain}/terms`} className="footer-link">Terms of Service</a>
                <span style={styles.separator}>|</span>
                <a href={`${domain}/privacy`} className="footer-link">Privacy Policy</a>
            </div>
        </footer>
    );
};

const styles = {
    footer: {
        backgroundColor: '#0e0911',
        color: '#0e0911',
        textAlign: 'center',
        padding: '10px 0',
        position: 'relative',
        bottom: 0,
        width: '100%',
    },
    footerText: {
        margin: 0,
        fontSize: '14px',
        color: '#e8e5ea',
    },
    footerLinks: {
        marginTop: '10px',  // Add some space between the text and the links
    },
    separator: {
        color: '#e8e5ea',
        margin: '0 5px',  // Add space around the separator
    },
};

export default Footer;
